html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

body * {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 500;
}

h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; }
h5 { font-size: 18px; }
h6 { font-size: 16px; }

p {
	margin: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

li { list-style-type: none; }

input,
select,
textarea,
button {
	font-family: inherit;
	line-height: inherit;
	letter-spacing: inherit;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
	outline: 0;
}

button {
	cursor: pointer;
}
